<template>
  <div>
    <v-form>
      <ValidationObserver ref="licenseInfoValidationObserver">
        <v-row class="ma-4">
          <v-col
            cols="12"
            xs="12"
            sm="6"
            lg="6"
            md="6"
            xl="6"
            v-if="getFieldAlias(116).Field_Visiblity == 'Yes'"
          >
            <ValidationProvider
              ref="licenseNoProvider"
              v-slot="{ errors }"
              :name="getFieldAlias(116).Field_Alias"
              rules="required"
            >
              <v-text-field
                :id="'LicenseNumber'"
                v-model="licenseNumber"
                :label="getFieldAlias(116).Field_Alias"
                outlined
                :counter="30"
                :maxlength="30"
                :error-messages="errors"
                @input="onFieldChange()"
              >
                <template v-slot:label>
                  {{ getFieldAlias(116).Field_Alias }}
                  <span
                    style="color: red"
                    v-if="getFieldAlias(116).Mandatory_Field == 'Yes'"
                    >*</span
                  >
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            lg="6"
            md="6"
            xl="6"
            v-if="getFieldAlias(117).Field_Visiblity == 'Yes'"
          >
            <v-dialog v-model="licenseIssueModal" width="290">
              <template #activator="{ on }">
                <ValidationProvider
                  ref="issueDateProvider"
                  v-slot="{ errors }"
                  :name="getFieldAlias(117).Field_Alias"
                  rules="required"
                >
                  <v-text-field
                    :id="'licenseIssueDate'"
                    outlined
                    :value="formatDate(licenseIssueDate)"
                    :label="getFieldAlias(117).Field_Alias"
                    v-on="on"
                    :error-messages="errors"
                    @input="onFieldChange()"
                  >
                    <template v-slot:label>
                      {{ getFieldAlias(117).Field_Alias }}
                      <span
                        style="color: red"
                        v-if="getFieldAlias(117).Mandatory_Field == 'Yes'"
                        >*</span
                      >
                    </template>
                  </v-text-field>
                </ValidationProvider>
              </template>
              <v-date-picker
                v-model="licenseIssueDate"
                :max="maxExpDate"
                @change="emptyLicenseExpiryDate()"
              ></v-date-picker>
            </v-dialog>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            lg="6"
            md="6"
            xl="6"
            v-if="getFieldAlias(118).Field_Visiblity == 'Yes'"
          >
            <v-dialog v-model="licenseExpiryModal" width="290">
              <template #activator="{ on }">
                <ValidationProvider
                  ref="expiryDateProvider"
                  v-slot="{ errors }"
                  :name="getFieldAlias(118).Field_Alias"
                  rules="required"
                >
                  <v-text-field
                    :id="'licenseExpiryDate'"
                    outlined
                    :value="formatDate(licenseExpiryDate)"
                    :label="getFieldAlias(118).Field_Alias"
                    :disabled="!licenseIssueDate"
                    v-on="on"
                    :error-messages="errors"
                    @input="onFieldChange()"
                  >
                    <template v-slot:label>
                      {{ getFieldAlias(118).Field_Alias }}
                      <span
                        style="color: red"
                        v-if="getFieldAlias(118).Mandatory_Field == 'Yes'"
                        >*</span
                      >
                    </template>
                  </v-text-field>
                </ValidationProvider>
              </template>
              <v-date-picker
                v-model="licenseExpiryDate"
                :min="licenseIssueDate"
                @change="licenseExpiryModal = false"
              ></v-date-picker>
            </v-dialog>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            lg="6"
            md="6"
            xl="6"
            v-if="getFieldAlias(119).Field_Visiblity == 'Yes'"
          >
            <ValidationProvider
              ref="authorityProvider"
              v-slot="{ errors }"
              :name="getFieldAlias(119).Field_Alias"
              rules="required"
            >
              <v-text-field
                :id="'LicenseIssuingAuthority'"
                v-model="licenseIssuingAuthority"
                outlined
                :counter="50"
                :maxlength="50"
                :label="getFieldAlias(119).Field_Alias"
                :error-messages="errors"
                @input="onFieldChange()"
              >
                <template v-slot:label>
                  {{ getFieldAlias(119).Field_Alias }}
                  <span
                    style="color: red"
                    v-if="getFieldAlias(119).Mandatory_Field == 'Yes'"
                    >*</span
                  >
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            lg="6"
            md="6"
            xl="6"
            v-if="getFieldAlias(120).Field_Visiblity == 'Yes'"
          >
            <ValidationProvider
              ref="countryProvider"
              v-slot="{ errors }"
              :name="getFieldAlias(120).Field_Alias"
              rules="required"
            >
              <v-autocomplete
                :id="'LicenseIssueCountry'"
                v-model="licenseIssueCountry"
                :items="countryList"
                item-text="Country_Name"
                item-value="Country_Code"
                outlined
                :error-messages="errors"
                :search-input.sync="countrySearch"
                @change="onFieldChange()"
              >
                <template v-slot:label>
                  {{ getFieldAlias(120).Field_Alias }}
                  <span
                    style="color: red"
                    v-if="getFieldAlias(120).Mandatory_Field == 'Yes'"
                    >*</span
                  >
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            lg="6"
            md="6"
            xl="6"
            v-if="getFieldAlias(121).Field_Visiblity == 'Yes'"
          >
            <ValidationProvider
              ref="vehicleTypeProvider"
              v-slot="{ errors }"
              :name="getFieldAlias(121).Field_Alias"
              rules="required"
            >
              <v-text-field
                :id="'VehicleType'"
                v-model="vehicleType"
                outlined
                :counter="30"
                :maxlength="30"
                :label="getFieldAlias(121).Field_Alias"
                :error-messages="errors"
                @input="onFieldChange()"
              >
                <template v-slot:label>
                  {{ getFieldAlias(121).Field_Alias }}
                  <span
                    style="color: red"
                    v-if="getFieldAlias(121).Mandatory_Field == 'Yes'"
                    >*</span
                  >
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            lg="6"
            md="6"
            xl="6"
            v-if="getFieldAlias(122).Field_Visiblity == 'Yes'"
          >
            <ValidationProvider
              ref="issuedStateProvider"
              v-slot="{ errors }"
              :name="getFieldAlias(122).Field_Alias"
              rules="required"
            >
              <v-text-field
                :id="'IssuingState'"
                v-model="issuingState"
                outlined
                :counter="30"
                :maxlength="30"
                :label="getFieldAlias(122).Field_Alias"
                :error-messages="errors"
                @input="onFieldChange()"
              >
                <template v-slot:label>
                  {{ getFieldAlias(122).Field_Alias }}
                  <span
                    style="color: red"
                    v-if="getFieldAlias(122).Mandatory_Field == 'Yes'"
                    >*</span
                  >
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" lg="6" xl="6" md="6" sm="6" xs="12">
            <DragAndDropFiles
              :id="'license'"
              file-category="document"
              :key-index="'license-file-upload'"
              :old-file-name="licenseFile.fileName ? licenseFile.fileName : ''"
              :is-error="fileValidationError"
              :Mandatory="
                getFieldAlias(116).Mandatory_Field == 'Yes' ? true : false
              "
              @file-event-success="updateFileName($event)"
              @file-event-triggered="isFileLoading = $event"
              :message="true"
            ></DragAndDropFiles>
          </v-col>
        </v-row>
      </ValidationObserver>
    </v-form>
    <!-- <v-overlay absolute :value="listLoading" color="#fff" z-index="1">
      <v-progress-circular
        color="primary"
        indeterminate
        size="50"
      ></v-progress-circular>
    </v-overlay> -->
  </div>
</template>

<script>
import { LIST_COUNTRY } from "@/graphql/OnboardingQueries";
import moment from "moment";
import DragAndDropFiles from "@/components/custom-components/DragAndDropFiles.vue";
import { scrollScreenToTop } from "../../../helper";

export default {
  name: "LicenseInfo",

  props: {
    personalInfo: {
      type: Object,
      required: true,
    },
    formList: {
      type: Array,
      required: true,
    },
  },

  components: {
    DragAndDropFiles,
  },

  data: () => ({
    //   fields
    licenseNumber: "",
    licenseIssueDate: "",
    licenseExpiryDate: "",
    licenseIssuingAuthority: "",
    licenseIssueCountry: "",
    vehicleType: "",
    issuingState: "",
    licenseFile: {},
    licenseLoading: true,

    //   others
    countryList: [],
    licenseIssueModal: false,
    licenseExpiryModal: false,
    isFileLoading: false,
    maxExpDate: "",
    countrySearch: "",
    fileValidationError: "",
  }),

  created() {
    this.maxExpDate = moment().format("YYYY-MM-DD");
  },

  apollo: {
    listCountry: {
      query: LIST_COUNTRY,
      client: "apolloClientA",
      variables() {
        return {
          Org_Code: localStorage.getItem("orgCode"),
          Url_Hash: localStorage.getItem("urlHash"),
        };
      },
      result({ data }) {
        if (data && data.listCountry) {
          this.countryList = data.listCountry.country;
        }
      },
    },
  },

  computed: {
    formatDate() {
      return (date) => {
        return date ? moment(date).format("DD/MM/YYYY") : "";
      };
    },
  },

  mounted() {
    scrollScreenToTop();
    this.licenseNumber = this.personalInfo.Driving_License_No;
    this.licenseIssueDate = this.personalInfo.License_Issue_Date;
    this.licenseExpiryDate = this.personalInfo.License_Expiry_Date;
    this.licenseIssuingAuthority = this.personalInfo.Issuing_Authority;
    this.licenseIssueCountry = this.personalInfo.Issuing_Country;
    this.vehicleType = this.personalInfo.Vehicle_Type;
    this.issuingState = this.personalInfo.Issuing_State;
    this.licenseFile["fileName"] = this.personalInfo.License_File_Name
      ? this.personalInfo.License_File_Name.split("?")[3]
      : "";
    this.licenseFile["fileNameWithoutFormat"] = this.personalInfo
      .License_File_Name
      ? this.personalInfo.License_File_Name
      : "";
    this.licenseLoading = false;
  },

  watch: {
    licenseIssueCountry() {
      this.countrySearch = "";
    },
    personalInfo() {
      this.licenseNumber = this.personalInfo.Driving_License_No;
      this.licenseIssueDate = this.personalInfo.License_Issue_Date;
      this.licenseExpiryDate = this.personalInfo.License_Expiry_Date;
      this.licenseIssuingAuthority = this.personalInfo.Issuing_Authority;
      this.licenseIssueCountry = this.personalInfo.Issuing_Country;
      this.vehicleType = this.personalInfo.Vehicle_Type;
      this.issuingState = this.personalInfo.Issuing_State;
      this.licenseFile["fileName"] = this.personalInfo.License_File_Name
        ? this.personalInfo.License_File_Name.split("?")[3]
        : "";
      this.licenseFile["fileNameWithoutFormat"] = this.personalInfo
        .License_File_Name
        ? this.personalInfo.License_File_Name
        : "";
    },
  },

  methods: {
    onFieldChange() {
      this.$emit("form-dirty");
    },
    getFieldAlias(fieldId) {
      return this.formList.find((field) => field.Field_Id === fieldId) || {};
    },
    updateFileName(fileName) {
      this.licenseFile.fileNameWithoutFormat = fileName;
      this.onFieldChange();
    },
    licenseRules(type) {
      if (
        this.licenseNumber ||
        this.licenseExpiryDate ||
        this.licenseIssueDate ||
        this.licenseIssueCountry ||
        this.issuingState ||
        this.licenseIssuingAuthority ||
        this.vehicleType
      ) {
        if (type === "authority") {
          return "alphaSpace|required";
        }
        if (type === "vehicleType") {
          return "alphaNumSpaceWithFiveSymbol|required";
        }
        if (type === "state") {
          return "alphaSpace|required";
        }
        return "required";
      }
      return "";
    },
    emptyLicenseExpiryDate() {
      this.licenseIssueModal = false;
      this.licenseExpiryDate = "";
    },
    licenseInfoDetails() {
      return {
        Driving_License_No: this.licenseNumber,
        License_Issue_Date:
          this.licenseIssueDate && this.licenseIssueDate != "Invalid date"
            ? this.licenseIssueDate
            : null,
        License_Expiry_Date:
          this.licenseExpiryDate && this.licenseExpiryDate != "Invalid date"
            ? this.licenseExpiryDate
            : null,
        Issuing_Authority: this.licenseIssuingAuthority,
        Issuing_Country: this.licenseIssueCountry,
        Issuing_State: this.issuingState,
        Vehicle_Type: this.vehicleType,
        License_File_Name: this.licenseFile["fileNameWithoutFormat"],
      };
    },
    validateLicenseInfo() {
      return new Promise((resolve) => {
        this.$refs.licenseInfoValidationObserver
          .validate()
          .then((validationResponse) => {
            if (this.licenseFile["fileNameWithoutFormat"] == "") {
              this.fileValidationError = "Document is required";
              resolve(false);
            }
            resolve(validationResponse);
          });
      });
    },
  },
};
</script>

<template>
  <div>
    <div>
      <v-switch v-model="checkRelative">
        <template v-slot:label>
          Do you have any relatives working in this company?
        </template>
      </v-switch>
    </div>
    <div v-if="checkRelative">
      <v-form>
        <ValidationObserver ref="relativeForm">
          <v-row>
            <v-col cols="12">
              <v-card flat class="pa-6">
                <v-row>
                  <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
                    <ValidationProvider
                      ref="rFirstNameProvider"
                      v-slot="{ errors }"
                      name="Relative Name"
                      rules="required|multilingualNameValidation"
                    >
                      <v-text-field
                        :id="'Relative_Name'"
                        v-model="relative.name"
                        outlined
                        :maxlength="150"
                        :error-messages="errors"
                      >
                        <template v-slot:label>
                          Relative Name
                          <span style="color: red">*</span>
                        </template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
                    <ValidationProvider
                      ref="rPositionProvider"
                      v-slot="{ errors }"
                      name="Position"
                      rules="required|positionValidation"
                    >
                      <v-text-field
                        :id="'Relative_Position'"
                        v-model="relative.position"
                        outlined
                        :maxlength="30"
                        :error-messages="errors"
                      >
                        <template v-slot:label>
                          Position
                          <span style="color: red">*</span>
                        </template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-form>
    </div>
    <div class="d-flex justify-end mb-3">
      <v-btn color="primary" text @click="addMoreDependent()">
        <v-icon>add</v-icon>
        Add Dependent
      </v-btn>
    </div>
    <v-form
      v-for="(dependent, index) in addDependent"
      :key="index"
      :ref="'dependentForm' + index"
    >
      <div
        v-if="addDependent.length > 1 || dependent.dependentId"
        class="d-flex justify-end mb-3"
      >
        <v-btn
          color="primary lighten-1"
          text
          @click="removeDependentDetails(index, dependent)"
        >
          <v-icon>delete</v-icon>
          Remove
        </v-btn>
      </div>
      <ValidationObserver :ref="'dependentForm' + index">
        <v-row>
          <v-col cols="12">
            <v-card flat class="pa-6">
              <v-row>
                <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
                  <ValidationProvider
                    ref="dFirstNameProvider"
                    v-slot="{ errors }"
                    name="First Name"
                    rules="required|multilingualNameValidation"
                  >
                    <v-text-field
                      :id="'First_Name'"
                      v-model="dependent.dFirstName"
                      outlined
                      label="First Name*"
                      :counter="50"
                      :maxlength="50"
                      :error-messages="errors"
                      @change="isFormDirty = true"
                      @input="isFormDirty = true"
                    >
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
                  <ValidationProvider
                    ref="dLastNameProvider"
                    v-slot="{ errors }"
                    name="Last Name"
                    rules="required|multilingualNameValidation"
                  >
                    <v-text-field
                      :id="'Last_Name'"
                      v-model="dependent.dLastName"
                      outlined
                      label="Last Name*"
                      :counter="50"
                      :maxlength="50"
                      :error-messages="errors"
                      @change="isFormDirty = true"
                      @input="isFormDirty = true"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
                  <ValidationProvider
                    ref="relationshipProvider"
                    v-slot="{ errors }"
                    name="Relationship"
                    rules="required"
                  >
                    <v-autocomplete
                      :id="'RelationShip'"
                      v-model="dependent.dRelation"
                      outlined
                      label="Relationship*"
                      :items="relationshipList"
                      item-text="Dependent_Relationship"
                      item-value="Dependent_Relationship"
                      :error-messages="errors"
                      @change="isFormDirty = true"
                    ></v-autocomplete>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
                  <v-dialog v-model="dependentDobModal" width="290">
                    <template #activator="{ on }">
                      <ValidationProvider
                        ref="dDobProvider"
                        v-slot="{ errors }"
                        name="DOB"
                        rules="required"
                      >
                        <v-text-field
                          :id="'DependentDob'"
                          :value="formatDate(dependent.dDob)"
                          outlined
                          readonly
                          label="Date of Birth*"
                          v-on="on"
                          :error-messages="errors"
                          @change="isFormDirty = true"
                          @input="isFormDirty = true"
                        ></v-text-field>
                      </ValidationProvider>
                    </template>
                    <v-date-picker
                      v-model="dependent.dDob"
                      :max="maxYearDependent"
                      @change="dependentDobModal = false"
                    ></v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" xs="12" sm="6" lg="6" md="6" xl="6">
                  <ValidationProvider
                    ref="genderProvider"
                    v-slot="{ errors }"
                    name="Gender"
                    rules="required"
                  >
                    <v-select
                      :id="'Gender'"
                      v-model="dependent.gender"
                      outlined
                      :items="genderList"
                      item-text="gender"
                      item-value="genderId"
                      :error-messages="errors"
                      label="Gender*"
                    ></v-select>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </ValidationObserver>
      <v-divider></v-divider>
    </v-form>
    <div class="mt-3 d-flex justify-end">
      <v-btn
        color="primary"
        style="border-radius: 10px"
        large
        :disabled="!checkRelative && !isFormDirty"
        :loading="saveLoading"
        @click="onSaveDependent()"
        >Save & Continue</v-btn
      >
      <v-btn
        v-if="!checkRelative"
        class="ml-2"
        elevation="2"
        text
        color="primary"
        style="border-radius: 10px"
        large
        @click="onSkipDependent()"
        >Skip</v-btn
      >
    </div>
  </div>
</template>

<script>
import {
  GET_DEPENDENT,
  ADD_DEPENDENT,
  UPDATE_DEPENDENT,
  DELETE_DEPENDENT,
  LIST_GENDERS,
} from "@/graphql/OnboardingQueries";
import moment from "moment";
import {
  ADD_UPDATE_RELATIVE,
  REMOVE_RELATIVE,
} from "../../../graphql/OnboardingQueries";
import { scrollScreenToTop } from "../../../helper";

export default {
  name: "DependentInfo",

  props: {
    relationshipList: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    addDependent: [],
    relative: {},
    dependentDobModal: false,
    maxYearDependent: "",
    apiCallCount: 0,
    saveLoading: false,
    genderList: [],
    isFormDirty: false,
    checkRelative: true,
    relativeTabSaved: true,
    dependentTabSaved: true,
  }),

  created() {
    this.maxYearDependent = moment().format("YYYY-MM-DD");
  },

  apollo: {
    retrieveGenderList: {
      query: LIST_GENDERS,
      variables() {
        return {
          Org_Code: localStorage.getItem("orgCode"),
        };
      },
      result({ data }) {
        if (
          data &&
          data.retrieveGenderList &&
          data.retrieveGenderList.genderData
        ) {
          this.genderList = data.retrieveGenderList.genderData;
        }
      },
    },
    getDependent: {
      query: GET_DEPENDENT,
      client: "apolloClientA",
      fetchPolicy: "no-cache",
      variables() {
        return {
          Org_Code: localStorage.getItem("orgCode"),
          Url_Hash: localStorage.getItem("urlHash"),
        };
      },
      result({ data }) {
        if (data && data.getDependent) {
          const { dependent, relation } = data.getDependent;
          if (dependent && dependent.length > 0) {
            let dependentList = [];
            this.isFormDirty = true;
            for (let depend of dependent) {
              let dependentOnj = {
                dFirstName: depend.Dependent_First_Name,
                dLastName: depend.Dependent_Last_Name,
                dRelation: depend.Relationship,
                dDob: depend.Dependent_DOB,
                dependentId: depend.Dependent_Id,
                gender: depend.Gender_Id,
              };
              dependentList.push(dependentOnj);
            }
            this.addDependent = dependentList;
          } else {
            this.addDependent.push({
              dFirstName: "",
              dLastName: "",
              dRelation: "",
              dDob: "",
              dependentId: 0,
              gender: null,
            });
          }
          if (relation && relation.length > 0) {
            this.relative = {
              name: relation[0].Relation_Name,
              position: relation[0].Position_Code,
              id: relation[0].Relation_Id,
            };
          }
        }
      },
    },
  },

  mounted() {
    scrollScreenToTop();
  },

  computed: {
    formatDate() {
      return (date) => {
        return date ? moment(date).format("DD/MM/YYYY") : "";
      };
    },
  },

  watch: {
    checkRelative(val) {
      if (!val && this.relative.id) {
        this.removeRelative(this.relative.id);
      } else if (!val) {
        this.relative = {};
      }
    },
    relativeTabSaved(val) {
      if (val && this.dependentTabSaved) {
        this.$emit("dependent-saved");
      }
    },
    dependentTabSaved(val) {
      if (val && this.relativeTabSaved) {
        this.$emit("dependent-saved");
      }
    },
  },

  methods: {
    addMoreDependent() {
      this.validateDependent().then((isValid) => {
        if (isValid) {
          this.addDependent.push({
            dFirstName: "",
            dLastName: "",
            dRelation: "",
            dDob: "",
            dependentId: 0,
            gender: null,
          });
        }
      });
    },

    validateDependent() {
      return new Promise((resolve) => {
        let callCount = 0,
          isValidCount = 0;
        for (let dIndex in this.addDependent) {
          this.$refs["dependentForm" + dIndex][0]
            .validate()
            .then((validationResponse) => {
              callCount += 1;
              if (validationResponse) {
                isValidCount += 1;
                if (callCount === this.addDependent.length) {
                  resolve(isValidCount === this.addDependent.length);
                }
              }
            });
        }
      });
    },
    validateRelative() {
      return new Promise((resolve) => {
        this.$refs["relativeForm"].validate().then((isValid) => {
          let invalidFields = [];
          Object.keys(this.$refs).forEach((refName) => {
            const field = this.$refs[refName];
            if (field && field.errors && field.errors.length > 0) {
              invalidFields.push(refName);
            }
          });
          if (invalidFields.length > 0) {
            let firstField = this.$refs[invalidFields[0]];
            firstField.$el.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
          resolve(isValid);
        });
      });
    },

    async onSaveDependent() {
      this.apiCallCount = 0;
      let relativeValid = true,
        dependentValid = true;
      if (this.checkRelative) {
        await this.validateRelative().then((isValid) => {
          relativeValid = isValid;
        });
      }
      if (this.isFormDirty) {
        await this.validateDependent().then((isValid) => {
          if (!isValid) {
            dependentValid = false;
          }
        });
      }
      if (relativeValid && dependentValid) {
        if (this.checkRelative) {
          this.relativeTabSaved = false;
          if (this.relative.id) {
            this.updateRelative();
          } else {
            this.addRelative();
          }
        }
        if (this.isFormDirty) {
          this.dependentTabSaved = false;
          for (let dependent of this.addDependent) {
            this.apiCallCount += 1;
            if (dependent.dependentId) {
              this.updateDependentDetails(dependent);
            } else {
              this.addDependentDetails(dependent);
            }
          }
        }
      }
    },

    addRelative() {
      let vm = this;
      vm.$apollo
        .mutate({
          mutation: ADD_UPDATE_RELATIVE,
          variables: {
            Org_Code: localStorage.getItem("orgCode"),
            Url_Hash: localStorage.getItem("urlHash"),
            Is_Update: 0,
            relationDetails: [
              {
                Relation_Name: vm.relative.name,
                Position_Code: vm.relative.position,
              },
            ],
            client: "apolloClientA",
          },
        })
        .then(() => {
          this.relativeTabSaved = true;
        })
        .catch((addErr) => {
          let snackbarData = {
            isOpen: true,
            message:
              addErr.graphQLErrors && addErr.graphQLErrors.length !== 0
                ? addErr.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
        });
    },
    updateRelative() {
      let vm = this;
      vm.$apollo
        .mutate({
          mutation: ADD_UPDATE_RELATIVE,
          variables: {
            Org_Code: localStorage.getItem("orgCode"),
            Url_Hash: localStorage.getItem("urlHash"),
            Is_Update: 1,
            relationDetails: [
              {
                Relation_Id: vm.relative.id,
                Relation_Name: vm.relative.name,
                Position_Code: vm.relative.position,
              },
            ],
            client: "apolloClientA",
          },
        })
        .then(() => {
          this.relativeTabSaved = true;
        })
        .catch((addErr) => {
          let snackbarData = {
            isOpen: true,
            message:
              addErr.graphQLErrors && addErr.graphQLErrors.length !== 0
                ? addErr.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
        });
    },

    removeRelative(id) {
      let vm = this;
      vm.$apollo
        .mutate({
          mutation: REMOVE_RELATIVE,
          variables: {
            Org_Code: localStorage.getItem("orgCode"),
            Url_Hash: localStorage.getItem("urlHash"),
            Relation_Id: id,
          },
          client: "apolloClientA",
        })
        .then(() => {
          this.relative = {};
          this.checkRelative = false;
          let snackbarData = {
            isOpen: true,
            message: "Relative details deleted successfully",
            type: "success",
          };
          vm.showAlert(snackbarData);
        })
        .catch((addErr) => {
          let snackbarData = {
            isOpen: true,
            message:
              addErr.graphQLErrors && addErr.graphQLErrors.length !== 0
                ? addErr.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
        });
    },

    onSkipDependent() {
      this.$emit("dependent-saved");
    },

    addDependentDetails(dependentItem) {
      let vm = this;
      vm.saveLoading = true;
      let genderTitle = this.genderList.filter((e) => {
        if (e.genderId == dependentItem.gender) {
          return e.gender;
        }
      });
      vm.$apollo
        .mutate({
          mutation: ADD_DEPENDENT,
          variables: {
            Org_Code: localStorage.getItem("orgCode"),
            Url_Hash: localStorage.getItem("urlHash"),
            Dependent_First_Name: dependentItem.dFirstName,
            Dependent_Last_Name: dependentItem.dLastName,
            Gender_Id: dependentItem.gender,
            Gender: genderTitle[0].gender,
            Relationship: dependentItem.dRelation,
            Dependent_DOB:
              dependentItem.dDob && dependentItem.dDob != "Invalid date"
                ? dependentItem.dDob
                : null,
          },
          client: "apolloClientA",
        })
        .then(() => {
          if (vm.apiCallCount === this.addDependent.length) {
            this.dependentTabSaved = true;
          }
        })
        .catch((addErr) => {
          let snackbarData = {
            isOpen: true,
            message:
              addErr.graphQLErrors.length !== 0
                ? addErr.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
          if (vm.apiCallCount === this.addDependent.length) {
            vm.saveLoading = false;
          }
        });
    },

    updateDependentDetails(dependentItem) {
      let vm = this;
      vm.saveLoading = true;
      let genderTitle = this.genderList.filter((e) => {
        if (e.genderId == dependentItem.gender) {
          return e.gender;
        }
      });
      vm.$apollo
        .mutate({
          mutation: UPDATE_DEPENDENT,
          variables: {
            Org_Code: localStorage.getItem("orgCode"),
            Url_Hash: localStorage.getItem("urlHash"),
            Dependent_Id: dependentItem.dependentId,
            Dependent_First_Name: dependentItem.dFirstName,
            Dependent_Last_Name: dependentItem.dLastName,
            Gender_Id: dependentItem.gender,
            Gender: genderTitle[0].gender,
            Relationship: dependentItem.dRelation,
            Dependent_DOB:
              dependentItem.dDob && dependentItem.dDob != "Invalid date"
                ? dependentItem.dDob
                : null,
          },
          client: "apolloClientA",
        })
        .then(() => {
          if (vm.apiCallCount === this.addDependent.length) {
            this.dependentTabSaved = true;
          }
        })
        .catch((addErr) => {
          let snackbarData = {
            isOpen: true,
            message:
              addErr.graphQLErrors.length !== 0
                ? addErr.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
          if (vm.apiCallCount === this.addDependent.length) {
            vm.saveLoading = false;
          }
        });
    },

    removeDependentDetails(index, dependentItem) {
      if (dependentItem.dependentId) {
        let vm = this;
        this.$apollo
          .mutate({
            mutation: DELETE_DEPENDENT,
            variables: {
              Org_Code: localStorage.getItem("orgCode"),
              Url_Hash: localStorage.getItem("urlHash"),
              Dependent_Id: dependentItem.dependentId,
            },
            client: "apolloClientA",
          })
          .then(() => {
            let snackbarData = {
              isOpen: true,
              message: "Deleted Successfully",
              type: "success",
            };
            vm.showAlert(snackbarData);
            vm.addDependent.splice(index, 1);
            if (index === 0 && vm.addDependent.length === 0) {
              vm.addDependent.push({
                dFirstName: "",
                dLastName: "",
                dRelation: "",
                dDob: "",
                dependentId: 0,
              });
            }
          })
          .catch((deleteErr) => {
            let snackbarData = {
              isOpen: true,
              message:
                deleteErr.graphQLErrors.length !== 0
                  ? deleteErr.graphQLErrors[0].message
                  : "Something went wrong. It could be a possibility due to network connectivity error..",
              type: "warning",
            };
            vm.showAlert(snackbarData);
          });
      } else {
        this.addDependent.splice(index, 1);
      }
    },

    // show error or success message
    showAlert(snackbarData) {
      this.$store.commit("OPEN_SNACKBAR", snackbarData);
    },
  },
};
</script>

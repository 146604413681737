<template>
  <div>
    <v-form>
      <ValidationObserver ref="passportInformationValidationObserver">
        <v-row class="ma-4">
          <v-col
            cols="12"
            xs="12"
            sm="6"
            lg="6"
            md="6"
            xl="6"
            v-if="getFieldAlias(123).Field_Visiblity == 'Yes'"
          >
            <ValidationProvider
              ref="passportNoProvider"
              v-slot="{ errors }"
              :name="getFieldAlias(123).Field_Alias"
              rules="required"
            >
              <v-text-field
                :id="'passportNumber'"
                v-model="passportNumber"
                :label="getFieldAlias(123).Field_Alias"
                outlined
                :counter="15"
                :maxlength="15"
                :error-messages="errors"
                @input="onFieldChange()"
              >
                <template v-slot:label>
                  {{ getFieldAlias(123).Field_Alias }}
                  <span
                    style="color: red"
                    v-if="getFieldAlias(123).Mandatory_Field == 'Yes'"
                    >*</span
                  >
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            lg="6"
            md="6"
            xl="6"
            v-if="getFieldAlias(124).Field_Visiblity == 'Yes'"
          >
            <v-dialog v-model="passportIssueModal" width="290">
              <template #activator="{ on }">
                <ValidationProvider
                  ref="passportIssueDateProvider"
                  v-slot="{ errors }"
                  :name="getFieldAlias(124).Field_Alias"
                  rules="required"
                >
                  <v-text-field
                    :id="'passportIssueDate'"
                    outlined
                    :value="formatDate(passportIssueDate)"
                    :label="getFieldAlias(124).Field_Alias"
                    v-on="on"
                    :error-messages="errors"
                    @input="onFieldChange()"
                  >
                    <template v-slot:label>
                      {{ getFieldAlias(124).Field_Alias }}
                      <span
                        style="color: red"
                        v-if="getFieldAlias(124).Mandatory_Field == 'Yes'"
                        >*</span
                      >
                    </template>
                  </v-text-field>
                </ValidationProvider>
              </template>
              <v-date-picker
                v-model="passportIssueDate"
                :max="maxExpDate"
                @change="emptypassportExpiryDate()"
              ></v-date-picker>
            </v-dialog>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            lg="6"
            md="6"
            xl="6"
            v-if="getFieldAlias(125).Field_Visiblity == 'Yes'"
          >
            <ValidationProvider
              ref="authorityProvider"
              v-slot="{ errors }"
              :name="getFieldAlias(125).Field_Alias"
              rules="required"
            >
              <v-text-field
                :id="'PassportIssuingAuthority'"
                v-model="passportIssuingAuthority"
                outlined
                :counter="50"
                :maxlength="50"
                :label="getFieldAlias(125).Field_Alias"
                :error-messages="errors"
                @input="onFieldChange()"
              >
                <template v-slot:label>
                  {{ getFieldAlias(125).Field_Alias }}
                  <span
                    style="color: red"
                    v-if="getFieldAlias(125).Mandatory_Field == 'Yes'"
                    >*</span
                  >
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            lg="6"
            md="6"
            xl="6"
            v-if="getFieldAlias(126).Field_Visiblity == 'Yes'"
          >
            <ValidationProvider
              ref="countryProvider"
              v-slot="{ errors }"
              :name="getFieldAlias(126).Field_Alias"
              rules="required"
            >
              <v-autocomplete
                :id="'passportIssuingCountry'"
                v-model="passportIssuingCountry"
                :items="countryList"
                item-text="Country_Name"
                item-value="Country_Code"
                :label="getFieldAlias(126).Field_Alias"
                outlined
                :search-input.sync="countrySearch"
                :error-messages="errors"
                @change="onFieldChange()"
              >
                <template v-slot:label>
                  {{ getFieldAlias(126).Field_Alias }}
                  <span
                    style="color: red"
                    v-if="getFieldAlias(126).Mandatory_Field == 'Yes'"
                    >*</span
                  >
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            lg="6"
            md="6"
            xl="6"
            v-if="getFieldAlias(127).Field_Visiblity == 'Yes'"
          >
            <v-dialog v-model="passportExpiryModal" width="290">
              <template #activator="{ on }">
                <ValidationProvider
                  ref="expiryDateProvider"
                  v-slot="{ errors }"
                  :name="getFieldAlias(127).Field_Alias"
                  rules="required"
                >
                  <v-text-field
                    :id="'passportExpiryDate'"
                    outlined
                    :value="formatDate(passportExpiryDate)"
                    :label="getFieldAlias(127).Field_Alias"
                    :disabled="!passportIssueDate"
                    v-on="on"
                    :error-messages="errors"
                    @input="onFieldChange()"
                  >
                    <template v-slot:label>
                      {{ getFieldAlias(127).Field_Alias }}
                      <span
                        style="color: red"
                        v-if="getFieldAlias(127).Mandatory_Field == 'Yes'"
                        >*</span
                      >
                    </template>
                  </v-text-field>
                </ValidationProvider>
              </template>
              <v-date-picker
                v-model="passportExpiryDate"
                :min="passportIssueDate"
                @change="passportExpiryModal = false"
              ></v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" lg="6" xl="6" md="6" sm="6" xs="12">
            <DragAndDropFiles
              :id="'passport'"
              file-category="document"
              :key-index="'passport-file-upload'"
              :old-file-name="
                passportFile.fileName ? passportFile.fileName : ''
              "
              :is-error="fileValidationError"
              @file-event-success="updateFileName($event)"
              @file-event-triggered="isFileLoading = $event"
              :message="true"
              :Mandatory="
                getFieldAlias(123).Mandatory_Field == 'Yes' ? true : false
              "
            ></DragAndDropFiles>
          </v-col>
        </v-row>
      </ValidationObserver>
    </v-form>
  </div>
</template>

<script>
import moment from "moment";
import { LIST_COUNTRY } from "@/graphql/OnboardingQueries";
import DragAndDropFiles from "@/components/custom-components/DragAndDropFiles.vue";
import { scrollScreenToTop } from "../../../helper";

export default {
  name: "PassportDetails",

  props: {
    personalInfo: {
      type: Object,
      required: true,
    },
    formList: {
      type: Array,
      required: true,
    },
  },
  components: { DragAndDropFiles },

  data: () => ({
    //   fields
    passportNumber: "",
    passportIssueDate: "",
    passportExpiryDate: "",
    passportIssuingAuthority: "",
    passportIssuingCountry: "",
    passportFile: {},
    //   others
    countryList: [],
    passportIssueModal: false,
    passportExpiryModal: false,
    isFileLoading: false,
    maxExpDate: "",
    countrySearch: "",
    fileValidationError: "",
  }),

  apollo: {
    listCountry: {
      query: LIST_COUNTRY,
      client: "apolloClientA",
      variables() {
        return {
          Org_Code: localStorage.getItem("orgCode"),
          Url_Hash: localStorage.getItem("urlHash"),
        };
      },
      result({ data }) {
        if (data && data.listCountry) {
          this.countryList = data.listCountry.country;
        }
      },
    },
  },

  created() {
    this.maxExpDate = moment().format("YYYY-MM-DD");
  },

  computed: {
    formatDate() {
      return (date) => {
        return date ? moment(date).format("DD/MM/YYYY") : "";
      };
    },
  },

  watch: {
    passportIssuingCountry() {
      this.countrySearch = "";
    },
  },

  mounted() {
    scrollScreenToTop();
    if (this.personalInfo && Object.keys(this.personalInfo).length > 0) {
      this.passportNumber = this.personalInfo.Passport_No;
      this.passportIssueDate = this.personalInfo.Issue_Date;
      this.passportIssuingCountry = this.personalInfo.Passport_Issuing_Country;
      this.passportIssuingAuthority =
        this.personalInfo.Passport_Issuing_Authority;
      this.passportExpiryDate = this.personalInfo.Expiry_Date;
      this.passportFile.fileName = this.personalInfo.Passport_File_Name
        ? this.personalInfo.Passport_File_Name.split("?")[3]
        : "";
      this.passportFile.fileNameWithoutFormat =
        this.personalInfo.Passport_File_Name;
    }
  },

  methods: {
    onFieldChange() {
      this.$emit("form-dirty");
    },
    getFieldAlias(fieldId) {
      return this.formList.find((field) => field.Field_Id === fieldId) || {};
    },
    updateFileName(fileName) {
      this.passportFile.fileNameWithoutFormat = fileName;
      this.onFieldChange();
    },
    passportRules(type) {
      if (
        this.passportNumber ||
        this.passportExpiryDate ||
        this.passportIssueDate ||
        this.passportIssuingCountry ||
        this.passportIssuingAuthority
      ) {
        if (type === "passportNumber") {
          return "alphaNumeric|min:6|max:15|required";
        }
        if (type === "authority") {
          return "alphaSpace|required";
        }
        return "required";
      }
      return "";
    },
    emptypassportExpiryDate() {
      this.passportIssueModal = false;
      this.passportExpiryDate = "";
    },
    passportInfoDetails() {
      return {
        Passport_No: this.passportNumber,
        Issue_Date:
          this.passportIssueDate && this.passportIssueDate != "Invalid date"
            ? this.passportIssueDate
            : null,
        Expiry_Date:
          this.passportExpiryDate && this.passportExpiryDate != "Invalid date"
            ? this.passportExpiryDate
            : null,
        Passport_Issuing_Country: this.passportIssuingCountry,
        Passport_Issuing_Authority: this.passportIssuingAuthority,
        Passport_File_Name: this.passportFile["fileNameWithoutFormat"],
      };
    },
    validatePassportInfo() {
      return new Promise((resolve) => {
        this.$refs.passportInformationValidationObserver
          .validate()
          .then((validationResponse) => {
            if (!this.passportFile["fileNameWithoutFormat"]) {
              this.fileValidationError = "Document is required";
              resolve(false);
            }
            resolve(validationResponse);
          });
      });
    },
  },
};
</script>

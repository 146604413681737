<template>
  <div>
    <div class="d-flex justify-end mb-3">
      <v-btn color="primary" text @click="addMoreEducation()">
        <v-icon>add</v-icon>Add Education
      </v-btn>
    </div>
    <v-form
      v-for="(education, index) in addEducation"
      :key="index"
      :ref="'educationForm' + index"
    >
      <div
        v-if="addEducation.length > 1 || education.educationId"
        class="d-flex justify-end mb-3"
      >
        <v-btn
          color="primary lighten-1"
          text
          @click="removeEducation(index, education)"
        >
          <v-icon>delete</v-icon>Remove
        </v-btn>
      </div>
      <ValidationObserver :ref="'educationForm' + index">
        <v-row>
          <v-col cols="12">
            <v-card flat class="pa-6">
              <v-row>
                <v-col
                  cols="12"
                  lg="6"
                  xl="6"
                  md="6"
                  sm="6"
                  xs="12"
                  v-if="getFieldAlias(128).Field_Visiblity == 'Yes'"
                >
                  <ValidationProvider
                    :ref="'sclClgDipProvider' + index"
                    v-slot="{ errors }"
                    :name="getFieldAlias(128).Field_Alias"
                    :rules="
                      getFieldAlias(128).Mandatory_Field == 'Yes'
                        ? 'required'
                        : ''
                    "
                  >
                    <v-autocomplete
                      :id="'School/Dip/Clg'"
                      v-model="education.schoolDiplomaDeg"
                      outlined
                      :items="courseList"
                      item-text="Course_Name"
                      item-value="Course_Id"
                      :label="getFieldAlias(128).Field_Alias"
                      :error-messages="errors"
                    >
                      <template v-slot:label>
                        {{ getFieldAlias(128).Field_Alias }}
                        <span
                          v-if="getFieldAlias(128).Mandatory_Field == 'Yes'"
                          style="color: red"
                          >*</span
                        >
                      </template>
                    </v-autocomplete>
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                  xl="6"
                  md="6"
                  sm="6"
                  xs="12"
                  v-if="getFieldAlias(129).Field_Visiblity == 'Yes'"
                >
                  <ValidationProvider
                    :ref="'specializationProvider' + index"
                    v-slot="{ errors }"
                    :name="getFieldAlias(129).Field_Alias"
                    :rules="
                      getFieldAlias(129).Mandatory_Field == 'Yes'
                        ? 'required'
                        : ''
                    "
                  >
                    <v-autocomplete
                      v-if="getFieldAlias(129).Predefined == 'Yes'"
                      :id="'Specialization'"
                      v-model="education.specializationId"
                      outlined
                      :items="specializationList"
                      item-text="Specialization"
                      item-value="Specialization_Id"
                      :label="getFieldAlias(129).Field_Alias"
                      :error-messages="errors"
                    >
                      <template v-slot:label>
                        {{ getFieldAlias(129).Field_Alias }}
                        <span
                          v-if="getFieldAlias(129).Mandatory_Field == 'Yes'"
                          style="color: red"
                          >*</span
                        >
                      </template>
                    </v-autocomplete>
                    <v-text-field
                      v-else
                      :id="'Specialization'"
                      v-model="education.specialization"
                      outlined
                      :error-messages="errors"
                    >
                      <template v-slot:label>
                        {{ getFieldAlias(129).Field_Alias }}
                        <span
                          v-if="getFieldAlias(129).Mandatory_Field == 'Yes'"
                          style="color: red"
                          >*</span
                        >
                      </template>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                  xl="6"
                  md="6"
                  sm="6"
                  xs="12"
                  v-if="getFieldAlias(130).Field_Visiblity == 'Yes'"
                >
                  <ValidationProvider
                    :ref="'instituteProvider' + index"
                    v-slot="{ errors }"
                    :name="getFieldAlias(130).Field_Alias"
                    :rules="
                      getFieldAlias(130).Mandatory_Field == 'Yes'
                        ? 'required'
                        : ''
                    "
                  >
                    <v-autocomplete
                      v-if="getFieldAlias(129).Predefined == 'Yes'"
                      :id="'InstituteName'"
                      v-model="education.instituteNameId"
                      outlined
                      :items="institutionList"
                      item-text="Institution"
                      item-value="Institution_Id"
                      :label="getFieldAlias(130).Field_Alias"
                      :error-messages="errors"
                    >
                      <template v-slot:label>
                        {{ getFieldAlias(130).Field_Alias }}
                        <span
                          v-if="getFieldAlias(130).Mandatory_Field == 'Yes'"
                          style="color: red"
                          >*</span
                        >
                      </template>
                    </v-autocomplete>
                    <v-text-field
                      v-else
                      :id="'InstituteName'"
                      v-model="education.instituteName"
                      outlined
                      :label="getFieldAlias(130).Field_Alias"
                      :error-messages="errors"
                    >
                      <template v-slot:label>
                        {{ getFieldAlias(130).Field_Alias }}
                        <span
                          v-if="getFieldAlias(130).Mandatory_Field == 'Yes'"
                          style="color: red"
                          >*</span
                        >
                      </template>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                  xl="6"
                  md="6"
                  sm="6"
                  xs="12"
                  v-if="getFieldAlias(131).Field_Visiblity == 'Yes'"
                >
                  <ValidationProvider
                    :ref="'universityProvider' + index"
                    v-slot="{ errors }"
                    :name="getFieldAlias(131).Field_Alias"
                    :rules="
                      getFieldAlias(131).Mandatory_Field == 'Yes'
                        ? 'required|multilingualNameNumericValidation'
                        : 'multilingualNameNumericValidation'
                    "
                  >
                    <v-text-field
                      :id="'InstituteName'"
                      v-model="education.university"
                      outlined
                      :counter="50"
                      :maxlength="50"
                      :label="getFieldAlias(131).Field_Alias"
                      :error-messages="errors"
                    >
                      <template v-slot:label>
                        {{ getFieldAlias(131).Field_Alias }}
                        <span
                          v-if="getFieldAlias(131).Mandatory_Field == 'Yes'"
                          style="color: red"
                          >*</span
                        >
                      </template>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="6"
                  lg="6"
                  md="6"
                  xl="6"
                  v-if="getFieldAlias(156).Field_Visiblity == 'Yes'"
                >
                  <ValidationProvider
                    :ref="'startDateProvider' + index"
                    v-slot="{ errors }"
                    name="Start Date"
                    :rules="
                      getFieldAlias(156).Mandatory_Field == 'Yes'
                        ? 'required'
                        : ''
                    "
                  >
                    <v-text-field
                      :id="'startDate'"
                      v-model="education.startDate"
                      type="date"
                      outlined
                      :error-messages="errors"
                    >
                      <template v-slot:label>
                        {{ getFieldAlias(156).Field_Name }}
                        <span
                          v-if="getFieldAlias(156).Mandatory_Field == 'Yes'"
                          style="color: red"
                          >*</span
                        >
                      </template>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="6"
                  lg="6"
                  md="6"
                  xl="6"
                  v-if="getFieldAlias(157).Field_Visiblity == 'Yes'"
                >
                  <ValidationProvider
                    :ref="'endDateProvider' + index"
                    v-slot="{ errors }"
                    name="End Date"
                    :rules="
                      getFieldAlias(157).Mandatory_Field == 'Yes'
                        ? 'required'
                        : ''
                    "
                  >
                    <v-text-field
                      :id="'EndDate'"
                      type="date"
                      v-model="education.endDate"
                      outlined
                      :error-messages="errors"
                    >
                      <template v-slot:label>
                        {{ getFieldAlias(157).Field_Name }}
                        <span
                          v-if="getFieldAlias(157).Mandatory_Field == 'Yes'"
                          style="color: red"
                          >*</span
                        >
                      </template>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="6"
                  lg="6"
                  md="6"
                  xl="6"
                  v-if="getFieldAlias(158).Field_Visiblity == 'Yes'"
                >
                  <ValidationProvider
                    :ref="'cCityProvider' + index"
                    v-slot="{ errors }"
                    name="City"
                    :rules="
                      getFieldAlias(158).Mandatory_Field == 'Yes'
                        ? 'required'
                        : ''
                    "
                  >
                    <v-autocomplete
                      v-if="getFieldAlias(158).Field_Visiblity == 'Yes'"
                      :id="'City'"
                      v-model="education.city"
                      :items="cityStateList"
                      item-text="cityStateDetails"
                      item-value="cityStateDetails"
                      outlined
                      :counter="50"
                      :maxlength="50"
                      label="City"
                      :error-messages="errors"
                      @change="findStateCountry(index)"
                    >
                      <template v-slot:label>
                        {{ getFieldAlias(158).Field_Name }}
                        <span
                          v-if="getFieldAlias(158).Mandatory_Field == 'Yes'"
                          style="color: red"
                          >*</span
                        >
                      </template></v-autocomplete
                    >
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="6"
                  lg="6"
                  md="6"
                  xl="6"
                  v-if="getFieldAlias(159).Field_Visiblity == 'Yes'"
                >
                  <ValidationProvider
                    :ref="'StateProvider' + index"
                    v-slot="{ errors }"
                    name="State"
                    :rules="
                      getFieldAlias(159).Mandatory_Field == 'Yes'
                        ? 'required'
                        : ''
                    "
                  >
                    <v-text-field
                      :id="'state'"
                      v-model="education.state"
                      outlined
                      :readonly="education.state ? true : false"
                      label="State"
                      :error-messages="errors"
                    >
                      <template v-slot:label>
                        {{ getFieldAlias(159).Field_Name }}
                        <span
                          v-if="getFieldAlias(159).Mandatory_Field == 'Yes'"
                          style="color: red"
                          >*</span
                        >
                      </template>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="6"
                  lg="6"
                  md="6"
                  xl="6"
                  v-if="getFieldAlias(160).Field_Visiblity == 'Yes'"
                >
                  <ValidationProvider
                    :ref="'CountryProvider' + index"
                    v-slot="{ errors }"
                    name="Country"
                    :rules="
                      getFieldAlias(160).Mandatory_Field == 'Yes'
                        ? 'required'
                        : ''
                    "
                  >
                    <v-autocomplete
                      :id="'Country'"
                      v-model="education.country"
                      :items="countryList"
                      item-text="Country_Name"
                      item-value="Country_Code"
                      outlined
                      :readonly="education.country ? true : false"
                      label="Country*"
                      :error-messages="errors"
                    >
                      <template v-slot:label>
                        {{ getFieldAlias(160).Field_Name }}
                        <span
                          v-if="getFieldAlias(160).Mandatory_Field == 'Yes'"
                          style="color: red"
                          >*</span
                        >
                      </template>
                    </v-autocomplete>
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                  xl="6"
                  md="6"
                  sm="6"
                  xs="12"
                  v-if="getFieldAlias(292).Field_Visiblity == 'Yes'"
                >
                  <ValidationProvider
                    :ref="'yearOfStartProvider' + index"
                    v-slot="{ errors }"
                    :name="getFieldAlias(292).Field_Alias"
                    :rules="
                      getFieldAlias(292).Mandatory_Field == 'Yes'
                        ? 'required'
                        : ''
                    "
                  >
                    <v-autocomplete
                      v-if="getFieldAlias(292).Predefined == 'Yes'"
                      :id="'YearOfStart'"
                      v-model="education.yearOfStart"
                      outlined
                      :items="startYearList"
                      :label="getFieldAlias(292).Field_Alias"
                      :error-messages="errors"
                      @change="education.yearOfPassing = null"
                    >
                      <template v-slot:label>
                        {{ getFieldAlias(292).Field_Alias }}
                        <span
                          v-if="getFieldAlias(292).Mandatory_Field == 'Yes'"
                          style="color: red"
                          >*</span
                        >
                      </template>
                    </v-autocomplete>
                    <v-text-field
                      v-else
                      :id="'YearOfStart'"
                      v-model="education.yearOfStart"
                      outlined
                      :error-messages="errors"
                    >
                      <template v-slot:label>
                        {{ getFieldAlias(292).Field_Alias }}
                        <span
                          v-if="getFieldAlias(292).Mandatory_Field == 'Yes'"
                          style="color: red"
                          >*</span
                        >
                      </template>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                  xl="6"
                  md="6"
                  sm="6"
                  xs="12"
                  v-if="getFieldAlias(134).Field_Visiblity == 'Yes'"
                >
                  <ValidationProvider
                    :ref="'yearOfPassingProvider' + index"
                    v-slot="{ errors }"
                    :name="getFieldAlias(134).Field_Alias"
                    :rules="
                      getFieldAlias(134).Mandatory_Field == 'Yes'
                        ? 'required'
                        : ''
                    "
                  >
                    <v-autocomplete
                      :id="'YearOfPassing'"
                      v-model="education.yearOfPassing"
                      outlined
                      :items="endYearList(index)"
                      :label="getFieldAlias(134).Field_Alias"
                      :error-messages="errors"
                    >
                      <template v-slot:label>
                        {{ getFieldAlias(134).Field_Alias }}
                        <span
                          v-if="getFieldAlias(134).Mandatory_Field == 'Yes'"
                          style="color: red"
                          >*</span
                        >
                      </template>
                    </v-autocomplete>
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                  xl="6"
                  md="6"
                  sm="6"
                  xs="12"
                  v-if="getFieldAlias(132).Field_Visiblity == 'Yes'"
                >
                  <ValidationProvider
                    :ref="'percentageProvider' + index"
                    v-slot="{ errors }"
                    :name="getFieldAlias(132).Field_Alias"
                    :rules="
                      getFieldAlias(132).Mandatory_Field == 'Yes'
                        ? `percentageValidation|required`
                        : `percentageValidation`
                    "
                  >
                    <v-text-field
                      :id="'Percentage'"
                      v-model="education.percentage"
                      type="number"
                      outlined
                      :label="getFieldAlias(132).Field_Alias"
                      :error-messages="errors"
                    >
                      <template v-slot:label>
                        {{ getFieldAlias(132).Field_Alias }}
                        <span
                          v-if="getFieldAlias(132).Mandatory_Field == 'Yes'"
                          style="color: red"
                          >*</span
                        >
                      </template>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                  xl="6"
                  md="6"
                  sm="6"
                  xs="12"
                  v-if="getFieldAlias(133).Field_Visiblity == 'Yes'"
                >
                  <ValidationProvider
                    :ref="'gradeProvider' + index"
                    v-slot="{ errors }"
                    :name="getFieldAlias(133).Field_Alias"
                    :rules="
                      getFieldAlias(133).Mandatory_Field == 'Yes'
                        ? 'required|alphaNumDot'
                        : 'alphaNumDot'
                    "
                  >
                    <v-text-field
                      :id="'Grade'"
                      v-model="education.grade"
                      outlined
                      :counter="10"
                      :maxlength="10"
                      :label="getFieldAlias(133).Field_Alias"
                      :error-messages="errors"
                    >
                      <template v-slot:label>
                        {{ getFieldAlias(133).Field_Alias }}
                        <span
                          v-if="getFieldAlias(133).Mandatory == 'Yes'"
                          style="color: red"
                          >*</span
                        >
                      </template>
                    </v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" lg="6" xl="6" md="6" sm="6" xs="12">
                  <DragAndDropFiles
                    :id="'edu' + index"
                    file-category="document"
                    :key-index="'edu-file-upload-' + index"
                    :old-file-name="education.fileName"
                    :Mandatory="true"
                    :is-error="education.fileValidationError"
                    @file-event-success="updateFileName($event, index)"
                    @file-event-triggered="isFileLoading = $event"
                    :message="true"
                  ></DragAndDropFiles>
                </v-col>
                <v-col
                  v-if="education.fileNameWithoutFormat"
                  cols="12"
                  lg="6"
                  xl="6"
                  md="6"
                  sm="6"
                  xs="12"
                >
                  <ValidationProvider
                    :ref="'eSubTypeProvider' + index"
                    v-slot="{ errors }"
                    name="Document Sub Type"
                    :rules="
                      education.fileNameWithoutFormat.length > 0
                        ? 'required'
                        : ''
                    "
                  >
                    <v-autocomplete
                      :id="'DocumentSubType'"
                      v-model="education.educationSubType"
                      outlined
                      :items="subTypeList"
                      item-text="Document_Sub_Type"
                      item-value="Document_Sub_Type_Id"
                      :error-messages="errors"
                    >
                      <template v-slot:label>
                        Document Sub Type
                        <span style="color: red">*</span>
                      </template>
                    </v-autocomplete>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </ValidationObserver>
      <v-divider></v-divider>
    </v-form>
    <div class="mt-3 d-flex justify-end">
      <v-btn
        color="primary"
        style="border-radius: 10px"
        large
        :loading="saveLoading"
        :disabled="isFileLoading"
        @click="onSaveEducation()"
        >Save & Continue</v-btn
      >
      <v-btn
        v-if="isEducationMandatory === 'No'"
        class="ml-2"
        elevation="2"
        text
        color="primary"
        style="border-radius: 10px"
        large
        @click="onSkipEducation()"
        >Skip</v-btn
      >
    </div>
    <v-overlay
      absolute
      :value="eduLoading || saveLoading"
      color="#fff"
      z-index="1"
    >
      <v-progress-circular
        color="primary"
        indeterminate
        size="50"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import {
  LIST_COURSE,
  GET_EDUCATION,
  ADD_EDUCATION,
  UPDATE_EDUCATION,
  DELETE_EDUCATION,
  LIST_SUB_DOC_TYPE,
  GET_FORM_FIELDS_AND_TYPE,
  GET_INSTITUTION_AND_SPECIALIZATION_LIST,
  GET_CITY_LIST_WITH_STATE,
  LIST_COUNTRY,
} from "@/graphql/OnboardingQueries";
import DragAndDropFiles from "@/components/custom-components/DragAndDropFiles.vue";
import { scrollScreenToTop } from "../../../helper";

export default {
  name: "Education",

  props: {
    showSkip: {
      type: Boolean,
      default: false,
    },
  },

  components: { DragAndDropFiles },

  data: () => ({
    addEducation: [],
    courseList: [],
    subTypeList: [],
    saveLoading: false,
    apiCallCount: 0,
    eduLoading: true,
    isFileLoading: false,
    isEducationMandatory: "No",
    formList: [],
    institutionList: [],
    specializationList: [],
    cityStateList: [],
    countryList: [],
    startDateModal: false,
    endDateModal: false,
    startDatePresentation: "",
    endDatePresentation: "",
  }),

  apollo: {
    listCountry: {
      query: LIST_COUNTRY,
      client: "apolloClientA",
      variables() {
        return {
          Org_Code: localStorage.getItem("orgCode"),
          Url_Hash: localStorage.getItem("urlHash"),
        };
      },
      result({ data }) {
        if (data && data.listCountry) {
          this.countryList = data.listCountry.country;
        }
      },
    },
    getCityListWithState: {
      query: GET_CITY_LIST_WITH_STATE,
      client: "apolloClientB",
      result({ data }) {
        this.cityStateList = data.getCityListWithState.cityDetails;
      },
    },
    retrieveListEduInstitutionAndSpecialization: {
      query: GET_INSTITUTION_AND_SPECIALIZATION_LIST,
      client: "apolloClientA",
      variables() {
        return {
          Org_Code: localStorage.getItem("orgCode"),
        };
      },
      result({ data }) {
        if (
          data &&
          data.retrieveListEduInstitutionAndSpecialization &&
          data.retrieveListEduInstitutionAndSpecialization.institution &&
          data.retrieveListEduInstitutionAndSpecialization.institution.length >
            0
        ) {
          this.institutionList =
            data.retrieveListEduInstitutionAndSpecialization.institution;
        }
        if (
          data &&
          data.retrieveListEduInstitutionAndSpecialization &&
          data.retrieveListEduInstitutionAndSpecialization.specialization &&
          data.retrieveListEduInstitutionAndSpecialization.specialization
            .length > 0
        ) {
          this.specializationList =
            data.retrieveListEduInstitutionAndSpecialization.specialization;
        }
      },
    },
    listDocumentSubType: {
      query: LIST_SUB_DOC_TYPE,
      client: "apolloClientA",
      variables() {
        return {
          Org_Code: localStorage.getItem("orgCode"),
          Url_Hash: localStorage.getItem("urlHash"),
          type_id: 3,
        };
      },
      result({ data }) {
        if (data && data.listDocumentSubType) {
          this.subTypeList = data.listDocumentSubType.documentSubType;
        }
      },
    },
    listCourseDetails: {
      query: LIST_COURSE,
      client: "apolloClientA",
      variables() {
        return {
          Org_Code: localStorage.getItem("orgCode"),
          Url_Hash: localStorage.getItem("urlHash"),
        };
      },
      result({ data }) {
        if (data && data.listCourseDetails) {
          this.courseList = data.listCourseDetails.courseDetails;
        }
      },
    },
    getEducation: {
      query: GET_EDUCATION,
      client: "apolloClientA",
      fetchPolicy: "no-cache",
      variables() {
        return {
          Org_Code: localStorage.getItem("orgCode"),
          Url_Hash: localStorage.getItem("urlHash"),
        };
      },
      result({ data }) {
        if (data && data.getEducation) {
          const { education } = data.getEducation;
          if (education && education.length > 0) {
            let educationList = [];
            for (let edu of education) {
              let educationOnj = {
                schoolDiplomaDeg: edu.Education_Type,
                specializationId: edu.Specialization_Id,
                instituteNameId: edu.Institution_Id,
                specialization: edu.Specialisation,
                instituteName: edu.Institute_Name,
                university: edu.University,
                yearOfStart: parseInt(edu.Year_Of_Start),
                yearOfPassing: parseInt(edu.Year_Of_Passing),
                percentage: !isNaN(parseFloat(edu.Percentage))
                  ? parseFloat(edu.Percentage)
                  : null,
                grade: edu.Grade,
                educationId: edu.Education_Id,
                fileName: edu.File_Name ? edu.File_Name.split("?")[3] : "",
                fileNameWithoutFormat: edu.File_Name,
                educationSubType: edu.Sub_Type_Id ? edu.Sub_Type_Id : null,
                startDate: edu.Start_Date ? edu.Start_Date.split("T")[0] : "",
                endDate: edu.End_Date ? edu.End_Date.split("T")[0] : "",
                city: edu.City,
                state: edu.State,
                country: edu.Country,
                fileValidationError: "",
              };
              educationList.push(educationOnj);
            }
            this.addEducation = educationList;
            this.$emit("education-done");
          } else {
            this.addEducation.push({
              schoolDiplomaDeg: null,
              specialization: "",
              specializationId: null,
              instituteName: "",
              instituteNameId: null,
              university: "",
              yearofStart: null,
              yearOfPassing: null,
              percentage: null,
              grade: "",
              educationId: "",
              fileName: "",
              fileNameWithoutFormat: "",
              educationSubType: null,
              startDate: "",
              endDate: "",
              city: "",
              state: "",
              country: "",
              fileValidationError: "",
            });
          }
        }
        this.eduLoading = false;
      },
      error() {
        this.eduLoading = false;
      },
    },
  },

  mounted() {
    scrollScreenToTop();
    this.getFieldProps();
  },

  computed: {
    startYearList() {
      let storedDob = localStorage.getItem("DOB");
      let year = 0;
      if (storedDob && storedDob !== "null") {
        year = new Date().getFullYear() - new Date(storedDob).getFullYear();
      } else {
        year = 80;
      }
      const now = new Date().getUTCFullYear();
      const years = Array(now - (now - year))
        .fill("")
        .map((v, idx) => now - idx);
      return years;
    },
    endYearList() {
      return (index) => {
        if (this.addEducation[index] && this.addEducation[index].yearOfStart) {
          return Array.from(
            { length: 10 },
            (v, i) => this.addEducation[index].yearOfStart + 1 + i
          );
        } else {
          return this.startYearList;
        }
      };
    },
  },

  methods: {
    findStateCountry(index) {
      for (let item of this.cityStateList) {
        if (item.cityStateDetails == this.addEducation[index].city) {
          let details = item.cityStateDetails.split(", ");
          this.addEducation[index].state = details[1];
          this.addEducation[index].country = item.Country_Code;
        }
      }
    },
    updateFileName(fileName, index) {
      this.addEducation[index]["fileNameWithoutFormat"] = fileName;
    },

    addMoreEducation() {
      this.validateEducation().then((isValid) => {
        if (isValid) {
          this.addEducation.push({
            schoolDiplomaDeg: null,
            specializationId: null,
            instituteNameId: null,
            specialization: "",
            instituteName: "",
            university: "",
            yearofStart: null,
            yearOfPassing: null,
            percentage: null,
            grade: "",
            educationId: "",
            fileName: "",
            fileNameWithoutFormat: "",
            educationSubType: null,
            startDate: "",
            endDate: "",
            city: "",
            state: "",
            country: "",
            fileValidationError: "",
          });
        }
      });
    },

    validateEducation() {
      return new Promise((resolve) => {
        let callCount = 0,
          isValidCount = 0;
        for (let eIndex in this.addEducation) {
          this.$refs["educationForm" + eIndex][0]
            .validate()
            .then((validationResponse) => {
              let invalidFields = [];
              Object.keys(this.$refs).forEach((refName) => {
                const field = this.$refs[refName];
                if (
                  field &&
                  field.length > 0 &&
                  field[0].errors &&
                  field[0].errors.length > 0
                ) {
                  invalidFields.push(refName);
                }
              });
              if (invalidFields.length > 0) {
                let firstField = this.$refs[invalidFields[0]][0];
                firstField.$el.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
              }
              callCount += 1;
              let eIndexFileName =
                this.addEducation[eIndex]["fileNameWithoutFormat"];
              if (validationResponse && eIndexFileName) {
                isValidCount += 1;
                if (callCount === this.addEducation.length) {
                  resolve(isValidCount === this.addEducation.length);
                }
              } else if (!eIndexFileName) {
                this.addEducation[eIndex]["fileValidationError"] =
                  "Document is required";
                let form = this.$refs["educationForm" + eIndex][0];
                form.$el.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
                resolve(false);
              }
            });
        }
      });
    },

    onSaveEducation() {
      this.apiCallCount = 0;
      for (let education of this.addEducation) {
        this.validateEducation().then((isValid) => {
          if (isValid) {
            this.apiCallCount += 1;
            if (education.educationId) {
              this.updateEducationDetails(education);
            } else {
              this.addEducationDetails(education);
            }
          }
        });
      }
    },

    onSkipEducation() {
      this.$emit("education-saved");
    },

    addEducationDetails(educationItem) {
      let vm = this;
      vm.saveLoading = true;
      vm.$apollo
        .mutate({
          mutation: ADD_EDUCATION,
          variables: {
            Org_Code: localStorage.getItem("orgCode"),
            Url_Hash: localStorage.getItem("urlHash"),
            Education_Type: educationItem.schoolDiplomaDeg
              ? educationItem.schoolDiplomaDeg
              : null,
            Specialization_Id: educationItem.specializationId,
            Institution_Id: educationItem.instituteNameId,
            Specialisation: educationItem.specialization,
            Institute_Name: educationItem.instituteName,
            University: educationItem.university,
            Year_Of_Start: educationItem.yearOfStart
              ? parseInt(educationItem.yearOfStart)
              : null,
            Year_Of_Passing: educationItem.yearOfPassing
              ? parseInt(educationItem.yearOfPassing)
              : null,
            Percentage: educationItem.percentage
              ? parseFloat(educationItem.percentage)
              : null,
            Document_Name: educationItem.documentName,
            Grade: educationItem.grade,
            File_Name: educationItem.fileNameWithoutFormat,
            Sub_Type_Id: educationItem.educationSubType,
            Start_Date:
              educationItem.startDate &&
              educationItem.startDate != "Invalid Date"
                ? educationItem.startDate
                : null,
            End_Date:
              educationItem.endDate && educationItem.endDate != "Invalid Date"
                ? educationItem.endDate
                : null,
            City: educationItem.city,
            State: educationItem.state,
            Country: educationItem.country,
          },
          client: "apolloClientA",
        })
        .then(() => {
          if (vm.apiCallCount === vm.addEducation.length) {
            vm.$emit("education-saved");
          }
        })
        .catch((addErr) => {
          let snackbarData = {
            isOpen: true,
            message:
              addErr.graphQLErrors && addErr.graphQLErrors.length !== 0
                ? addErr.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
          if (vm.apiCallCount === vm.addEducation.length) {
            vm.saveLoading = false;
          }
        });
    },

    updateEducationDetails(educationItem) {
      let vm = this;
      vm.saveLoading = true;
      vm.$apollo
        .mutate({
          mutation: UPDATE_EDUCATION,
          variables: {
            Org_Code: localStorage.getItem("orgCode"),
            Url_Hash: localStorage.getItem("urlHash"),
            Education_Id: educationItem.educationId,
            Education_Type: educationItem.schoolDiplomaDeg
              ? educationItem.schoolDiplomaDeg
              : null,
            Specialization_Id: educationItem.specializationId,
            Specialisation: educationItem.specialization,
            Institution_Id: educationItem.instituteNameId,
            Institute_Name: educationItem.instituteName,
            University: educationItem.university,
            Year_Of_Start: educationItem.yearOfStart
              ? parseInt(educationItem.yearOfStart)
              : null,
            Year_Of_Passing: educationItem.yearOfPassing
              ? parseInt(educationItem.yearOfPassing)
              : null,
            Percentage: educationItem.percentage
              ? parseFloat(educationItem.percentage)
              : null,
            Grade: educationItem.grade,
            File_Name: educationItem.fileNameWithoutFormat,
            Document_Name: educationItem.documentName,
            Sub_Type_Id: educationItem.educationSubType,
            Start_Date:
              educationItem.startDate &&
              educationItem.startDate != "Invalid Date"
                ? educationItem.startDate
                : null,
            End_Date:
              educationItem.endDate && educationItem.endDate != "Invalid Date"
                ? educationItem.endDate
                : null,
            City: educationItem.city,
            State: educationItem.state,
            Country: educationItem.country,
          },
          client: "apolloClientA",
        })
        .then(() => {
          if (vm.apiCallCount === vm.addEducation.length) {
            vm.$emit("education-saved");
          }
        })
        .catch((addErr) => {
          let snackbarData = {
            isOpen: true,
            message:
              addErr.graphQLErrors && addErr.graphQLErrors.length !== 0
                ? addErr.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
          if (vm.apiCallCount === vm.addEducation.length) {
            vm.saveLoading = false;
          }
        });
    },

    removeEducation(index, educationItem) {
      if (educationItem.educationId) {
        let vm = this;
        this.$apollo
          .mutate({
            mutation: DELETE_EDUCATION,
            variables: {
              Org_Code: localStorage.getItem("orgCode"),
              Url_Hash: localStorage.getItem("urlHash"),
              Education_Id: educationItem.educationId,
            },
            client: "apolloClientA",
          })
          .then(() => {
            let snackbarData = {
              isOpen: true,
              message: "Deleted Successfully",
              type: "success",
            };
            vm.showAlert(snackbarData);
            vm.addEducation.splice(index, 1);
            if (index === 0 && vm.addEducation.length === 0) {
              vm.addEducation.push({
                companyName: "",
                location: "",
                designation: "",
                from: "",
                to: "",
                fileName: "",
                fileNameWithoutFormat: "",
                educationId: 0,
                educationSubType: 0,
                documentName: "",
              });
            }
          })
          .catch((deleteErr) => {
            let snackbarData = {
              isOpen: true,
              message:
                deleteErr.graphQLErrors.length !== 0
                  ? deleteErr.graphQLErrors[0].message
                  : "Something went wrong. It could be a possibility due to network connectivity error..",
              type: "warning",
            };
            vm.showAlert(snackbarData);
          });
      } else {
        this.addEducation.splice(index, 1);
      }
    },

    getFieldAlias(fieldId) {
      return this.formList.find((field) => field.Field_Id === fieldId) || {};
    },
    getFieldProps() {
      let vm = this;
      vm.saveLoading = true;
      this.$apollo
        .query({
          query: GET_FORM_FIELDS_AND_TYPE,
          variables: {
            form_Id: 178,
          },
          client: "apolloClientB",
        })
        .then((res) => {
          if (res.data.getFormFeildsByFormIdAndTab) {
            this.formList = res.data.getFormFeildsByFormIdAndTab.formFields;
            this.isEducationMandatory = this.getFieldAlias(129).Mandatory_Field;
          }
          vm.saveLoading = false;
        })
        .catch(() => {
          vm.saveLoading = false;
        });
    },
    // show error or success message
    showAlert(snackbarData) {
      this.$store.commit("OPEN_SNACKBAR", snackbarData);
    },
  },
};
</script>
